import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.min.js";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Catalogo from './components/Catalogo';
import Navigation from './components/Navigation';
import Damas from './components/Damas';
import Caballeros from './components/Caballeros';
import Sets from './components/Sets';
import Infantiles from './components/Infantiles';
import Contacto from './components/Contacto';
import './App.css';


function App() {
  return (
    <Router>
      <Navigation />
      <Route path="/" exact component={Catalogo} /> 
      <Route path="/damas" component={Damas} />
      <Route path="/caballeros" component={Caballeros} />
      <Route path="/sets" component={Sets} />
      <Route path="/infantiles" component={Infantiles} />
      <Route path="/contacto" component={Contacto} />
    </Router>
  );
}

export default App;
