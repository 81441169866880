import React from 'react'
import img1 from '../img/Img_inicio_1.jpg'
import img2 from '../img/Img_inicio_2.jpg'
import img3 from '../img/Img_inicio_3.jpg'
import img4 from '../img/Img_inicio_4.jpg'
import img5 from '../img/Img_inicio_5.jpg'
import img6 from '../img/Img_inicio_6.jpg'
import img7 from '../img/Img_inicio_7.jpg'
import img8 from '../img/Img_inicio_8.jpg'
import img9 from '../img/Img_inicio_9.jpg'
import img10 from '../img/Img_inicio_10.jpg'
import img11 from '../img/Img_inicio_11.jpg'
import img12 from '../img/Img_inicio_12.jpg'
import img13 from '../img/Img_inicio_13.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import axios from '../config/axios';

function Catalogo() {

    const [busqueda, setBusqueda] = useState('');
    const [data, setData] = useState({ 'actual': 0, 'total': 0, 'items': [] });
    const { actual, total, items } = data;

    const buscar = async e => {
        e.preventDefault();
        if (!busqueda) {
            return;
        }
        const respuesta = await axios(`/api/v1/productos/busqueda?page=${0}&busqueda=${busqueda}`);
        setData({ 'actual': 0, 'total': respuesta.data.total, 'items': respuesta.data.items });
    }

    const anterior = async () => {
        const respuesta = await axios(`/api/v1/productos/busqueda?page=${actual - 1}&busqueda=${busqueda}`);
        setData({ 'actual': actual - 1, 'total': respuesta.data.total, 'items': respuesta.data.items });
    }

    const siguiente = async () => {
        const respuesta = await axios(`/api/v1/productos/busqueda?page=${actual + 1}&busqueda=${busqueda}`);
        setData({ 'actual': actual + 1, 'total': respuesta.data.total, 'items': respuesta.data.items });
    }

    const limpiar = () => {
        setData({ 'actual': 0, 'total': 0, 'items': [] });
    }

    const pintar = items.map((item) => {
        return <div className="col-md-4 p-2" key={item._id}>
            <div className="card bg-light mb-3">
                <div className="card-header">
                    <h5 className="card-title">{item.MARCA}</h5>
                    <h6 className="card-subtitle mb-2 text-muted">{item.GENERO}</h6>
                </div>
                <div className="card-body bg-white">
                    <center>
                        <img src={item.FOTO} alt="" width="50%" height="50%" />
                    </center>
                </div>
                <div className="card-body">
                    <p className="font-weight-light small">{item.DESCRIPCION}</p>
                </div>
                <a className="btn btn-success m-2"
                    href={"https://api.whatsapp.com/send?phone=+525545346914&text=!Lo%20Quiero! " + item.DESCRIPCION}
                    target="_blanck" role="button">¡Lo quiero!</a>
            </div>
        </div>
    })

    return (
        <>
            <form className="form-inline" onSubmit={buscar}>
                <div className="row mt-3">
                    <div className="col-md-6 offset-md-3">
                        <div className="input-group mb-3">
                            <input type="search" className="form-control" placeholder="Buscar" aria-label="Buscar"
                                value={busqueda}
                                onChange={e => setBusqueda(e.target.value)}
                            />
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" type="submit">
                                    <FontAwesomeIcon icon={faSearch} />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <button type="button" className="btn btn-dark" onClick={limpiar}>Limpiar</button>
                    </div>
                </div>
            </form>
            {total > 0 &&
                <div className="container mb-5">
                    <div className="row">
                        {pintar}
                    </div>
                    <div className="col text-center">
                        {actual > 0 &&
                            <button className="btn btn-dark" onClick={anterior}>
                                <FontAwesomeIcon icon={faChevronLeft} /> Anterior
                            </button>
                        }
                        <button className="btn btn-dark mx-2">
                            {actual + 1} / {Math.ceil(total / 21)}
                        </button>
                        {actual + 1 < Math.ceil(total / 21) &&
                            <button className="btn btn-dark" onClick={siguiente}>
                                Siguiente<FontAwesomeIcon icon={faChevronRight} />
                            </button>
                        }
                    </div>
                </div>
            }
            <div className="container bg-white shadow border px-5 py-3 mb-5">
                <h3 className='text-center mb-3'>Nuestras recomendaciones</h3>
                <div className="row">
                    {/* img1 */}
                    <div className="col-md-4 p-2">
                        <div className="card bg-light mb-3">
                            <div className="card-header">
                                <center>
                                    Cavally Fragances
                                </center>
                            </div>
                            <div className="card-body">
                                <center>
                                    <img src={img1} alt="" width="64%" height="50%" />
                                </center>
                            </div>
                            <div className="card-body">
                                <p>GENERO:DAMA</p>
                                <p>MARCA: CACHAREL</p>
                                <p>DESCRIPCION:SET AMOR 2 PZAS EDT SPRAY 100ML/ EDT SPRAY 30 ML</p>
                            </div>
                            <a className="btn btn-success " href="https://api.whatsapp.com/send?phone=+525545346914&text=!Lo%20Quiero!%20SET%20AMOR%202%20PZAS%20EDT%20SPRAY%20100ML/%20EDT%20SPRAY%2030%20ML" target="_blanck" role="button">¡Lo quiero!</a>
                        </div>
                    </div>
                    {/* img2 */}
                    <div className="col-md-4 p-2">
                        <div className="card bg-light mb-3">
                            <div className="card-header">
                                <center>
                                    Cavally Fragances
                                </center>
                            </div>
                            <div className="card-body">
                                <center>
                                    <img src={img2} alt="" width="55%" height="50%" />
                                </center>
                            </div>
                            <div className="card-body">
                                <p>GENERO:DAMA</p>
                                <p>MARCA:CAROLINA HERRERA</p>
                                <p>DESCRIPCION:SET CH PRIVEE 2PZS 80ML EDP SPRAY/ BODY LOTION 100ML</p>
                            </div>
                            <a className="btn btn-success " href="https://api.whatsapp.com/send?phone=+525545346914&text=!Lo%20Quiero!%20SET%20CH%20PRIVEE%202PZS%2080ML%20EDP%20SPRAY/%20BODY%20LOTION%20100ML" target="_blanck" role="button">¡Lo quiero!</a>
                        </div>
                    </div>
                    {/* img3 */}
                    <div className="col-md-4 p-2">
                        <div className="card bg-light mb-3">
                            <div className="card-header">
                                <center>
                                    Cavally Fragances
                                </center>
                            </div>
                            <div className="card-body">
                                <center>
                                    <img src={img3} alt="" width="50%" height="50%" />
                                </center>
                            </div>
                            <div className="card-body">
                                <p>GENERO:DAMA</p>
                                <p>MARCA:COACH</p>
                                <p>DESCRIPCION:SET COACH DREAMS 3PZS 90ML EDP SPRAY/ BODY LOTION 100ML/ 7.5ML EDP SPRAY</p>
                            </div>
                            <a className="btn btn-success " href="https://api.whatsapp.com/send?phone=+525545346914&text=!Lo%20Quiero!%20SET%20COACH%20DREAMS%203PZS%2090ML%20EDP%20SPRAY/%20BODY%20LOTION%20100ML/%207.5ML%20EDP%20SPRAY" target="_blanck" role="button">¡Lo quiero!</a>
                        </div>
                    </div>
                    {/* img4 */}
                    <div className="col-md-4 p-2">
                        <div className="card bg-light mb-3">
                            <div className="card-header">
                                <center>
                                    Cavally Fragances
                                </center>
                            </div>
                            <div className="card-body">
                                <center>
                                    <img src={img4} alt="" width="80%" height="50%" />
                                </center>
                            </div>
                            <div className="card-body">
                                <p>GENERO:CABALLERO</p>
                                <p>MARCA:GUCCI</p>
                                <p>DESCRIPCION:SET GUCCI GUILTY 3 PZS  90 ML EDT SPRAY/ DEODORANT STICK POUR HOMME 75ML/ GUCCY GUILTY TRAVEL PARFUM 10 ML</p>
                            </div>
                            <a className="btn btn-success " href="https://api.whatsapp.com/send?phone=+525545346914&text=!Lo%20Quiero!%20SET%20GUCCI%20GUILTY%203%20PZS%20%2090%20ML%20EDT%20SPRAY/%20DEODORANT%20STICK%20POUR%20HOMME%2075ML/%20GUCCY%20GUILTY%20TRAVEL%20PARFUM%2010%20ML" target="_blanck" role="button">¡Lo quiero!</a>
                        </div>
                    </div>
                    {/* img5 */}
                    <div className="col-md-4 p-2">
                        <div className="card bg-light mb-3">
                            <div className="card-header">
                                <center>
                                    Cavally Fragances
                                </center>
                            </div>
                            <div className="card-body">
                                <center>
                                    <img src={img5} alt="" width="88%" height="50%" />
                                </center>
                            </div>
                            <div className="card-body">
                                <p>GENERO:CABALLERO</p>
                                <p>MARCA:JEAN PAUL GAULTIER</p>
                                <p>DESCRIPCION: SET JEAN PAUL GAULTIER LE MALE LE PARFUM 2PZS EDP 125ML SPRAY/ SHOWER GEL 75ML </p>
                            </div>
                            <a className="btn btn-success " href="https://api.whatsapp.com/send?phone=+525545346914&text=!Lo%20Quiero!%20SET%20JEAN%20PAUL%20GAULTIER%20LE%20MALE%20LE%20PARFUM%202PZS%20EDP%20125ML%20SPRAY/%20SHOWER%20GEL%2075ML" target="_blanck" role="button">¡Lo quiero!</a>
                        </div>
                    </div>
                    {/* img6 */}
                    <div className="col-md-4 p-2">
                        <div className="card bg-light mb-3">
                            <div className="card-header">
                                <center>
                                    Cavally Fragances
                                </center>
                            </div>
                            <div className="card-body">
                                <center>
                                    <img src={img6} alt="" width="50%" height="50%" />
                                </center>
                            </div>
                            <div className="card-body">
                                <p>GENERO:CABALLERO</p>
                                <p>MARCA:LACOSTE</p>
                                <p>DESCRIPCION:SET LACOSTE BLANC 3PZS 100ML EDT SPRAY/ SHOWER GEL 50ML/ DESODORANTE STICK 75ML</p>
                            </div>
                            <a className="btn btn-success " href="https://api.whatsapp.com/send?phone=+525545346914&text=!Lo%20Quiero!%20SET%20LACOSTE%20BLANC%203PZS%20100ML%20EDT%20SPRAY/%20SHOWER%20GEL%2050ML/%20DESODORANTE%20STICK%2075ML" target="_blanck" role="button">¡Lo quiero!</a>
                        </div>
                    </div>
                    {/* img7 */}
                    <div className="col-md-4 p-2">
                        <div className="card bg-light mb-3">
                            <div className="card-header">
                                <center>
                                    Cavally Fragances
                                </center>
                            </div>
                            <div className="card-body">
                                <center>
                                    <img src={img7} alt="" width="50%" height="50%" />
                                </center>
                            </div>
                            <div className="card-body">
                                <p>GENERO:DAMA</p>
                                <p>MARCA:LANCOME</p>
                                <p>DESCRIPCION:SET LANCOME IDOLE 3PZS 75ML EDP SPRAY/ BODY LOTION 50ML/ 10ML EDP SPRAY</p>
                            </div>
                            <a className="btn btn-success " href="https://api.whatsapp.com/send?phone=+525545346914&text=!Lo%20Quiero!%20SET%20LANCOME%20IDOLE%203PZS%2075ML%20EDP%20SPRAY/%20BODY%20LOTION%2050ML/%2010ML%20EDP%20SPRAY" target="_blanck" role="button">¡Lo quiero!</a>
                        </div>
                    </div>
                    {/* img8 */}
                    <div className="col-md-4 p-2">
                        <div className="card bg-light mb-3">
                            <div className="card-header">
                                <center>
                                    Cavally Fragances
                                </center>
                            </div>
                            <div className="card-body">
                                <center>
                                    <img src={img8} alt="" width="50%" height="50%" />
                                </center>
                            </div>
                            <div className="card-body">
                                <p>GENERO:CABALLERO</p>
                                <p>MARCA:MERCEDES BENZ</p>
                                <p>DESCRIPCION:SET MERCEDES BENZ 3 PZS  EDT 100ML, SHOWER GEL 90 ML/ AFTER SHAVE 90 ML</p>
                            </div>
                            <a className="btn btn-success " href="https://api.whatsapp.com/send?phone=+525545346914&text=!Lo%20Quiero!%20SET%20MERCEDES%20BENZ%203%20PZS%20%20EDT%20100ML,%20SHOWER%20GEL%2090%20ML/%20AFTER%20SHAVE%2090%20ML" target="_blanck" role="button">¡Lo quiero!</a>
                        </div>
                    </div>
                    {/* img9 */}
                    <div className="col-md-4 p-2">
                        <div className="card bg-light mb-3">
                            <div className="card-header">
                                <center>
                                    Cavally Fragances
                                </center>
                            </div>
                            <div className="card-body">
                                <center>
                                    <img src={img9} alt="" width="50%" height="50%" />
                                </center>
                            </div>
                            <div className="card-body">
                                <p>GENERO:CABALLERO</p>
                                <p>MARCA:PACO RABANNE</p>
                                <p>DESCRIPCION:SET ONE MILLION 3PZS PARFUM 100 ML/ SHOWER GEL 100ML/ PARFUM TRAVEL SPRAY 10 ML</p>
                            </div>
                            <a className="btn btn-success " href="https://api.whatsapp.com/send?phone=+525545346914&text=!Lo%20Quiero!%20SET%20ONE%20MILLION%203PZS%20PARFUM%20100%20ML/%20SHOWER%20GEL%20100ML/%20PARFUM%20TRAVEL%20SPRAY%2010%20ML" target="_blanck" role="button">¡Lo quiero!</a>
                        </div>
                    </div>
                    {/* img10 */}
                    <div className="col-md-4 p-2">
                        <div className="card bg-light mb-3">
                            <div className="card-header">
                                <center>
                                    Cavally Fragances
                                </center>
                            </div>
                            <div className="card-body">
                                <center>
                                    <img src={img10} alt="" width="50%" height="50%" />
                                </center>
                            </div>
                            <div className="card-body">
                                <p>GENERO:DAMA</p>
                                <p>MARCA:MOSCHINO</p>
                                <p>DESCRIPCION:SET MOSCHINO TOY2, 3 PZS/ EDP 100 ML SPRAY/ BODY LOTION 1OO ML/ TRAVEL PARFUM EDP 10 ML</p>
                            </div>
                            <a className="btn btn-success " href="https://api.whatsapp.com/send?phone=+525545346914&text=!Lo%20Quiero!%20SET%20MOSCHINO%20TOY2,%203%20PZS/%20EDP%20100%20ML%20SPRAY/%20BODY%20LOTION%201OO%20ML/%20TRAVEL%20PARFUM%20EDP%2010%20ML" target="_blanck" role="button">¡Lo quiero!</a>
                        </div>
                    </div>
                    {/* img11 */}
                    <div className="col-md-4 p-2">
                        <div className="card bg-light mb-3">
                            <div className="card-header">
                                <center>
                                    Cavally Fragances
                                </center>
                            </div>
                            <div className="card-body">
                                <center>
                                    <img src={img11} alt="" width="55%" height="50%" />
                                </center>
                            </div>
                            <div className="card-body">
                                <p>GENERO:DAMA</p>
                                <p>MARCA: JEAN PAUL GAULTIER </p>
                                <p>DESCRIPCION:SET JEAN PAUL GAULTIER SCANDAL 2PZS 80ML EDP SPRAY/ BOLSA</p>
                            </div>
                            <a className="btn btn-success " href="https://api.whatsapp.com/send?phone=+525545346914&text=!Lo%20Quiero!%20SET%20JEAN%20PAUL%20GAULTIER%20SCANDAL%202PZS%2080ML%20EDP%20SPRAY/%20BOLSA" target="_blanck" role="button">¡Lo quiero!</a>
                        </div>
                    </div>
                    {/* img12 */}
                    <div className="col-md-4 p-2">
                        <div className="card bg-light mb-3">
                            <div className="card-header">
                                <center>
                                    Cavally Fragances
                                </center>
                            </div>
                            <div className="card-body">
                                <center>
                                    <img src={img12} alt="" width="50%" height="50%" />
                                </center>
                            </div>
                            <div className="card-body">
                                <p>GENERO:DAMA</p>
                                <p>MARCA:ARIANA GRANDE</p>
                                <p>DESCRIPCION:SET ARIANA GRANDE SWEET LIKE CANDY 3PZS 100ML EDP SPRAY/ BODY LOTION 100ML/ SHOWER GEL 100 ML</p>
                                {/* <p>PRECIO_TIENDA:{user.P_PUBLICO_TIENDADEPARTAMENTAL_C_IVA }</p>
                                    <p>DESCUENTO:{user.DESCUENTO}</p> */}
                            </div>
                            <a className="btn btn-success " href="https://api.whatsapp.com/send?phone=+525545346914&text=!Lo%20Quiero!%20SET%20ARIANA%20GRANDE%20SWEET%20LIKE%20CANDY%203PZS%20100ML%20EDP%20SPRAY/%20BODY%20LOTION%20100ML/%20SHOWER%20GEL%20100%20ML" target="_blanck" role="button">¡Lo quiero!</a>
                        </div>
                    </div>
                    {/* img12 */}
                    <div className="col-md-4 p-2">
                        <div className="card bg-light mb-3">
                            <div className="card-header">
                                <center>
                                    Cavally Fragances
                                </center>
                            </div>
                            <div className="card-body">
                                <center>
                                    <img src={img13} alt="" width="50%" height="50%" />
                                </center>
                            </div>
                            <div className="card-body">
                                <p>GENERO:DAMA</p>
                                <p>MARCA:CAROLINA HERRERA</p>
                                <p>DESCRIPCION:SET CH VERY GOOD GIRL 2 PZS 80ML EDP SPRAY/ BODY LOTION 100ML</p>
                            </div>
                            <a className="btn btn-success " href="https://api.whatsapp.com/send?phone=+525545346914&text=!Lo%20Quiero!%20SET%20CH%20VERY%20GOOD%20GIRL%202%20PZS%2080ML%20EDP%20SPRAY/%20BODY%20LOTION%20100ML" target="_blanck" role="button">¡Lo quiero!</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Catalogo;
