import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import axios from '../config/axios';

export default function Contacto() {
    const [valido, setValido] = useState({ nombreRegistro: '', telefonoRegistro: '', correoRegistro: '', estadoRegistro: '', comentariosRegistro: '' })
    const [datos, setDatos] = useState({
        nombre: '',
        telefono: '',
        correo: '',
        estado: '',
        comentarios: ''
    });
    const [errorDatos, setErrorDatos] = useState(false);
    const [exitoEnvio, setExitoEnvio] = useState({ estado: false, mensaje: '' });
    const [sePuedeEnviar, setSePuedeEnviar] = useState(true);
    const [pensando, setPensando] = useState(false);
    const { nombre, telefono, correo, estado, comentarios } = datos;
    const { nombreRegistro, telefonoRegistro, correoRegistro, estadoRegistro, comentariosRegistro } = valido;

    const presetDatos = e => {
        setDatos({ ...datos, [e.target.name]: e.target.value });
        switch (e.target.name) {
            case 'nombre':
                if (!e.target.value) {
                    setValido({ ...valido, nombreRegistro: 'is-invalid' })
                    return;
                }
                setValido({ ...valido, nombreRegistro: 'is-valid' })
                break;
            case 'telefono':
                setValido({ ...valido, telefonoRegistro: 'is-valid' })
                break;
            case 'correo':
                const regex = /\S+@\S+\.\S+/;
                if (!e.target.value || !regex.test(e.target.value)) {
                    setValido({ ...valido, correoRegistro: 'is-invalid' })
                    return;
                }
                setValido({ ...valido, correoRegistro: 'is-valid' })
                break;
            case 'estado':
                setValido({ ...valido, estadoRegistro: 'is-valid' })
                break;
            case 'comentarios':
                setValido({ ...valido, comentariosRegistro: 'is-valid' })
                break;
            default:
                break;
        }
    };

    const enviar = async e => {
        e.preventDefault();
        setPensando(true);
        setErrorDatos(false);
        setSePuedeEnviar(false);
        if (nombre.trim() === '' || telefono.trim() === '' || correo.trim() === '') {
            setErrorDatos(true);
            setSePuedeEnviar(true);
            setPensando(false);
            return;
        }
        try {
            const respuesta = await axios.post('/api/v1/registro/crear', { nombre, telefono, correo, estado, comentarios });
            if (respuesta.data.estado) {
                setExitoEnvio({ estado: true, mensaje: respuesta.data.mensaje });
            }
        } catch (error) {
            console.log(error);
            setSePuedeEnviar(true);
        }
        setPensando(false);
    };

    return (
        <div className="container-fluid bg-azul" >
            <br />
            <div className="row">
                <div className="col-lg-6 col-md-8 mx-auto min-vh-100">
                    <div className="card shadow p-3 mb-5 bg-white rounded">
                        {/* prueba 1 */}
                        <div className="row">
                            <div className="col-sm-4"></div>
                            <div className="col-sm-4" style={{ width: "80px" }}><h1>01/</h1></div>
                            <div className="col-sm-4" style={{ width: "150px" }}><h4>PONTE EN<br />CONTACTO</h4></div>
                        </div>
                        <div className="card-body">
                            {/* <h1 className="titulo-1 mb-4 text-center">Pre-Registro</h1> */}
                            <div className="form-group">
                                <div className="fa fa-bars fa-4x" style={{ fontIcon: "10em", color: "gray" }}>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </div>
                                <p>contacto@gmail.com</p>
                            </div>
                            <div className="form-group">
                                <div className="fa fa-bars fa-4x" style={{ fontIcon: "10em", color: "gray" }}>
                                    <FontAwesomeIcon icon={faInstagram} />
                                </div>
                                <p>@Cavally Fragances</p>

                                <div className="fa fa-bars fa-4x" style={{ fontIcon: "10em", color: "gray" }}>
                                    <FontAwesomeIcon icon={faFacebook} />
                                </div>
                                <p>facebook - Cavally Fragances</p>

                                <a href="https://api.whatsapp.com/send?phone=+525545346914&text=hola!&nbsp;me&nbsp;pueden&nbsp;apoyar?" target="_blanck">
                                    <div className="fa fa-bars fa-4x " style={{ fontIcon: "10em", color: "green" }}>
                                        <FontAwesomeIcon icon={faWhatsapp} />
                                    </div>
                                </a>
                                <h3 className="text-dark ">WhatsApp</h3>
                                <p>Haz click en el icono para chatear con Cavally Fragances</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-8 mx-auto min-vh-100">
                    <div className="card shadow p-3 mb-5 bg-white rounded">
                        {/* prueba 1 */}
                        <div className="row">
                            <div className="col-xs-6 col-sm-4"></div>
                            <div className="col-sm-4" style={{ width: "85px" }}><h1>02/</h1></div>
                            <div className="col-sm-4" style={{ width: "150px" }}><h4>¿TIENES<br />DUDAS?</h4></div>
                            <div className="row"><center>Envianos un mensaje</center></div>
                        </div>
                        {/* final de prueba */}
                        <div className="card-body">
                            {/* <h1 className="titulo-1 mb-4 text-center"></h1> */}
                            <form className="form needs-validation" onSubmit={enviar} noValidate>
                                <div className="form-group">
                                    <label className="texto" htmlFor="nombre">Nombre completo: </label>
                                    <input className={`form-control mb-2 ${nombreRegistro}`} type="text" placeholder="Ej. María Lopez Hernandez" required
                                        name="nombre" value={nombre} onChange={presetDatos} />
                                    <div className="invalid-feedback">Ingrese su nombre completo</div>
                                </div>
                                <div className="form-group">
                                    <label className="texto" htmlFor="telefono">Teléfono: </label>
                                    <input className={`form-control mb-2 ${telefonoRegistro}`} type="telefono" placeholder="Ej. 55 2525 8585" required
                                        name="telefono" id="telefono" value={telefono} onChange={presetDatos} />
                                    <div className="invalid-feedback">Ingrese su número de teléfono</div>
                                </div>
                                <div className="form-group">
                                    <label className="texto" htmlFor="correo">Correo: </label>
                                    <input className={`form-control mb-2 ${correoRegistro}`} type="email" placeholder="Ej. correo@empresa.com" required
                                        name="correo" value={correo} onChange={presetDatos} />
                                    <div className="invalid-feedback">Ingrese un correo válido</div>
                                </div>
                                <div className="form-group">
                                    <label className="texto" htmlFor="estado">Estado: </label>
                                    <select className={`form-control rounded-5 mb-2 ${estadoRegistro}`} name="estado" value={estado} onChange={presetDatos}>
                                        <option value=" aguascalientes"> Aguascalientes</option>
                                        <option value=" california"> Baja California</option>
                                        <option value=" california sur"> Baja California Sur</option>
                                        <option value=" campeche"> Campeche</option>
                                        <option value=" coahuila"> Coahuila</option>
                                        <option value=" colima"> Colima</option>
                                        <option value=" chiapas"> Chiapas</option>
                                        <option value=" chihuahua"> Chihuahua</option>
                                        <option value=" durango"> Durango</option>
                                        <option value=" distrito Federal"> Distrito Federal</option>
                                        <option value=" guanajuato"> Guanajuato</option>
                                        <option value=" guerrero"> Guerrero</option>
                                        <option value=" hidalgo"> Hidalgo</option>
                                        <option value=" jalisco"> Jalisco</option>
                                        <option value=" méxico"> México</option>
                                        <option value=" michoacán"> Michoacán</option>
                                        <option value=" morelos"> Morelos</option>
                                        <option value=" nayarit"> Nayarit</option>
                                        <option value=" nuevo León"> Nuevo León</option>
                                        <option value=" oaxaca"> Oaxaca</option>
                                        <option value=" puebla"> Puebla</option>
                                        <option value=" queretaro"> Queretaro</option>
                                        <option value=" quintana Roo"> Quintana Roo</option>
                                        <option value=" san Luis Potosí"> San Luis Potosí</option>
                                        <option value=" sinaloa"> Sinaloa</option>
                                        <option value=" sonora"> Sonora</option>
                                        <option value=" tabasco"> Tabasco</option>
                                        <option value=" tamaulipas"> Tamaulipas</option>
                                        <option value=" tlaxcala"> Tlaxcala</option>
                                        <option value=" veracruz"> Veracruz</option>
                                        <option value=" yucatan"> Yucatan</option>
                                        <option value=" zacatecas"> Zacatecas</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label className="texto" htmlFor="comentario">Comentarios: </label>
                                    <input className={`form-control mb-2 ${comentariosRegistro}`} type="comentarios" placeholder="Comentario" name="comentarios" value={comentarios} onChange={presetDatos} />
                                </div>
                                <center>
                                    {(sePuedeEnviar && !pensando) &&
                                        <button className="btn btn-primary btn-lg btn-block my-1" type="submit">Enviar</button>
                                    }
                                </center>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <p className="text-center small m-2"> Copyright Cad&Lan© 2021</p>
        </div>
    )
}