import React, { useState, useEffect } from "react";
import axios from '../config/axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
function Infantiles() {
    const [infantil, setInfantil] = useState([]);
    const [pagina, setPagina] = useState(0)
    const [totalPaginas, setTotalPaginas] = useState(0)

    useEffect(() => {
        const Paginfantil = async e => {
            const resultado = await axios(`/api/v1/productos/infantil?page=${pagina}`)
            setInfantil(resultado.data)
        };
    Paginfantil();
    }, [pagina]);

    useEffect(() => {
        const Pieinfantil = async e => {
            const resultado = await axios(`/api/v1/productos/pieinfantil`)
            setTotalPaginas(Math.ceil(resultado.data.length / 21))
        };
    Pieinfantil();
    });

    const anterior = () => {
        if (pagina !== 0) { }
        return setPagina(pagina - 1)
    }

    const siguiente = () => {
        if (pagina === totalPaginas) { }
        return setPagina(pagina + 1)
    }

    const useaxiosPosts = infantil.map((user) => {
        return <div className="col-md-4 p-2" key={user._id}>
            <div className="card bg-light mb-3">
                <div className="card-header">
                    <center>
                        Cavally Fragances
                    </center>
                </div>
                <div className="card-body">
                    <center>
                        <img src={user.FOTO} alt="" width="50%" height="50%" />
                    </center>
                </div>
                <div className="card-body">
                    <p>GENERO:{user.GENERO}</p>
                    <p>MARCA:{user.MARCA}</p>
                    <p>DESCRIPCION:{user.DESCRIPCION}</p>
                    {/* <p>PRECIO_OUTLET:{user.PRECIO_OUTLET_C_IVA }</p>
            <p>PRECIO_TIENDA:{user.P_PUBLICO_TIENDADEPARTAMENTAL_C_IVA }</p> */}
                    {/* <p>DESCUENTO:{user.DESCUENTO}</p> */}
                </div>
            </div>
        </div>
    })
    return (
        <div className="container">
            <div className="row">
                {infantil && useaxiosPosts}
            </div>
            <div className="col text-center">
                {(infantil.length <= 100 && pagina !== 0) &&
                    <button className="btn btn-dark" onClick={anterior}>
                        <FontAwesomeIcon icon={faChevronLeft} /> Anterior
                    </button>
                }
                {infantil.length <= 100 &&
                    <button className="btn btn-dark">
                        {pagina + 1} / {totalPaginas}
                    </button>
                }
                {(infantil.length <= 100 && pagina !== totalPaginas - 1) &&
                    <button className="btn btn-dark" onClick={siguiente}>
                        Siguiente<FontAwesomeIcon icon={faChevronRight} />
                    </button>
                }
            </div>
        </div>
    );
}
export default Infantiles;

